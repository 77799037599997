import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

class ArchivePosts extends Component {
    constructor(props) {
        super(props)
        if (props.posts.length === 0) {
            return "Loading..."
        }
    }
    movieOrImage(data) {
        const post = data;
        if (post.acf.movie) {
            let posterURL = ''
            if (post.featured_media) {
                posterURL = post.featured_media.localFile.publicURL
            } else {
                posterURL = null
            }
            return (
                <video className="w-full" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={posterURL}>
                    <source src={post.acf.movie.localFile.publicURL} type="video/mp4" />
                </video>
            )
        } else if (post.featured_media) {
            return (<Img fluid={post.featured_media.localFile.childImageSharp.fluid} />)
        } else {
            return null;
        }
    }

    renderCategories(categories) {
        let array = [];
        for (let index = 0; index < categories.length; index++) {
            const category = categories[index];
            array.push(
                <span className="link-small text-teal" to={category.path} key={index}>{category.name}/</span>
            );
        }
        // _.join(array, '/')
        // console.log(array);
        return array;
    }

    renderPosts() {
        return this.props.posts
            .slice(0, this.props.postsShown)
            // .map(posts => <li key={posts.node.wordpress_id}>{posts.node.title}</li>)
            .map(posts =>
                <div className="w-full lg:w-1/2 relative text-white lg:px-6 wide:px-8 mb-100 wide:mb-150" data-aos="fade-up" key={posts.node.wordpress_id}>
                    <div className="text-white no-underline">
                        <Link to={posts.node.path} className="text-white no-underline">
                            {this.movieOrImage(posts.node)}
                        </Link>
                        <h3 className="mb-50 mt-10 md:mt-50 lg:mt-6 lg:mb-6 wide:mb-10">{this.renderCategories(posts.node.categories)}</h3>
                        <Link to={posts.node.path} className="text-white no-underline">
                            <h2 className="title-standard">
                                {posts.node.title}
                            </h2>
                            <div className="text-standard mt-8 lg:hidden" dangerouslySetInnerHTML={{ __html: posts.node.acf.ingress }} />
                        </Link>
                    </div>
                </div>
            )
    }

    render() {
        return this.renderPosts();
    }
}

export default ArchivePosts