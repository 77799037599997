import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/layout"
import ArchivePosts from "../components/archive/archive-posts"
import ArchiveCase from "../components/archive/archive-case"
import Button from '../components/button';
import SEO from "../components/seo"
// import Filter from '../components/archive/Filter';

class ArchiveTemplate extends Component {

    constructor(props) {
        super(props)
        this.currentPage = this.props.data.wordpressPage
        // console.log(this.currentPage.acf.archive_type);
        let data, buttonText;
        if(this.currentPage.acf.archive_type === 'case') {
            data = props.data.allWordpressWpCase.edges
            buttonText = 'Fler case';
        } else if (this.currentPage.acf.archive_type === 'posts') {
            data = props.data.allWordpressPost.edges;
            buttonText = 'Fler nyheter';
        }
        this.state = {
            posts: data,
            postsShown: 20,
            buttonText: buttonText
        }
    }
    componentDidUpdate() {
        // console.log(this.state.postsShown);
        // console.log('här ska vi disablea fler case knappen');
    }
    showMore = () => {
        if (this.state.postsShown + 6 <= this.state.posts.length) {
            this.setState(state => ({ postsShown: state.postsShown + 6 }));
        } else {
            this.setState({ postsShown: this.state.posts.length })
        }
    }

    getDataTemplate() {
        if (this.currentPage.acf.archive_type === 'case') {
            return (<ArchiveCase caseData={this.state.posts} postsShown={this.state.postsShown} />)
        } else if (this.currentPage.acf.archive_type === 'posts') {
            return (<ArchivePosts posts={this.state.posts} postsShown={this.state.postsShown} />)
        }
    }


    render() {
        // const categories = this.props.data.allWordpressCategory.nodes
        return (
            <Layout footerfixed={true} headerClass="relative">
                <SEO
                    title={this.currentPage.title}
                    description={this.currentPage.content}
                />
                <main className="container px-5 xl:px-0">
                    <div className="w-full lg:w-2/3 mx-auto pt-100 md:pt-200 lg:pt-250 wide:pt-300 mb-100 lg:mb-150 wide:mb-250">
                        <h1 className="title-lg" dangerouslySetInnerHTML={{ __html: this.currentPage.acf.title }} />
                        <article className="text-large text-white mt-6 md:mt-8 wide:mt-50" dangerouslySetInnerHTML={{ __html: this.currentPage.content }} />
                    </div>
                    {/* <Filter categories={categories} /> */}
                    <section className="archive bg-black pb-32">
                        <div className="overflow-hidden">
                            <div className="flex flex-wrap lg:-mx-6 wide:-mx-8">
                                {this.getDataTemplate()}
                            </div>
                        {/* <Button text={this.state.buttonText} className="link-standard text-teal" onClick={this.showMore} icon="arrow-down" animate={true} btncolor="bg-red"/>  */}
                        </div>
                    </section>
                </main>
            </Layout>
        )
    }
}

export default ArchiveTemplate

export const pageQuery = graphql`
query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      title
      content
      acf {
        title
        archive_type
      }
    }
    allWordpressPost(sort: {fields: date, order: DESC}){
        edges {
            node {
                wordpress_id
                title
                path
                date
                featured_media {
                    localFile {
                        publicURL
                        childImageSharp {
                            fluid(maxWidth: 1700, quality: 100) {
                            ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                }
                acf {
                    ingress
                    movie {
                        id
                        localFile {
                            publicURL
                        }
                    }
                }
                categories {
                    wordpress_id
                    name
                    slug
                    path
                }
            }
        }
    }
    allWordpressCategory {
        nodes {
            wordpress_id
            wordpress_parent
            path
            name
        }
    }
    allWordpressWpCase(sort: {fields: date, order: DESC}){
        edges {
            node {
                wordpress_id
                title
                path
                date
                acf {
                    client
                    hover_text
                    landscape {
                        id
                        localFile {
                            publicURL
                        }
                    }
                    portrait {
                        id
                        localFile {
                            publicURL
                        }
                    }
                    landscape_case {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxHeight: 730, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                    portrait_case {
                        localFile {
                            publicURL
                            childImageSharp {
                                fluid(maxHeight: 730, quality: 100){
                                ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
}
`