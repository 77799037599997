import React, { Component } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

class ArchivePosts extends Component {
    constructor(props) {
        super(props)
        if (props.caseData.length === 0) {
            return "Loading..."
        }
    }
    calculateLayout(i) {
        let width;
        
        if ((i + 1) % 5 === 0) {
            width = 'w-full  xl:w-2/3';
        } else {
            width = 'w-full md:w-1/2 xl:w-1/3';
        }
        
        return width;
    }
    movieOrImage(data, index) {
        const caseData = data
        // console.log(caseData)
        if (caseData.acf.landscape || caseData.acf.portrait) {
            let landscapePosterURL, portraitPosterURL;
            if (caseData.acf.landscape_case) {
                landscapePosterURL = caseData.acf.landscape_case.localFile.publicURL
                portraitPosterURL = caseData.acf.portrait_case.localFile.publicURL
            } else {
                landscapePosterURL = null
                portraitPosterURL = null
            }
            if ((index + 1) % 5 === 0) {
                return (
                    <div className="h-full relative overflow-hidden">
                        <video className="video w-full absolute" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={landscapePosterURL}>
                            <source src={caseData.acf.landscape.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.acf.landscape_case.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0" />
                    </div>
                )
            } else {
                return (
                    <div className="h-full relative overflow-hidden">
                        <video className="video w-full hidden md:block" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={portraitPosterURL}>
                            <source src={caseData.acf.portrait.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.acf.portrait_case.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 hidden md:block" />
                        <video className="video w-full md:hidden" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={landscapePosterURL}>
                            <source src={caseData.acf.landscape.localFile.publicURL} type="video/mp4" />
                        </video>
                        <Img fluid={caseData.acf.landscape_case.localFile.childImageSharp.fluid} className="image image--one-third h-full opacity-0 md:hidden" />
                    </div>
                )
            }
                
            
        } else if (caseData.acf.landscape_case) {
            if ((index + 1) % 5 === 0 ) {
                return (
                    <div className="h-full">
                        <Img fluid={caseData.acf.landscape_case.localFile.childImageSharp.fluid} className="image image--two-third h-full" />
                    </div>
                )
            } else {
                return (
                    <div className="h-full">
                        <Img fluid={caseData.acf.portrait_case.localFile.childImageSharp.fluid} className="image image--one-third h-full hidden md:block" />
                        <Img fluid={caseData.acf.landscape_case.localFile.childImageSharp.fluid} className="image h-full md:hidden" />
                    </div>
                )
            }
        }
    }

    // renderCategories(categories) {
    //     let array = [];
    //     for (let index = 0; index < categories.length; index++) {
    //         const category = categories[index];
    //         array.push(
    //             <span className="link-small text-teal" to={category.path} key={index}>{category.name}/</span>
    //         );
    //     }
    //     // _.join(array, '/')
    //     // console.log(array);
    //     return array;
    // }

    renderPosts() {
        return this.props.caseData
            .slice(0, this.props.postsShown)
            .map((caseData, index) =>
                <div className={this.calculateLayout(index) + " case "} data-aos="fade-up" key={caseData.node.wordpress_id}>
                    <div className="mb-50 md:mb-100 wide:mb-175">
                        <Link to={caseData.node.path} className="text-white no-underline">
                            <div className="text-white no-underline h-full relative overflow-hidden">
                                {this.movieOrImage(caseData.node, index)}
                                <div className="case-hover w-full">
                                    <div className="case-content bg-red h-full">
                                        <div className="flex flex-col w-full justify-center h-full px-12 wide:px-16 ">
                                            <h3 className="subject-small text-base xl:text-lg text-white mb-8" dangerouslySetInnerHTML={{ __html: caseData.node.acf.client }} />
                                            <h2 className="title-standard mb-8" dangerouslySetInnerHTML={{ __html: caseData.node.title }} />
                                            <div className="hidden lg:block text-standard" dangerouslySetInnerHTML={{ __html: caseData.node.acf.hover_text }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h2 className="title-standard mt-5 md:mt-6 lg:mt-8" dangerouslySetInnerHTML={{ __html: caseData.node.title }}  />
                            <h3 className="mt-2 text-white font-thin font-AkkuratPro" dangerouslySetInnerHTML={{ __html: caseData.node.acf.client }} />
                        </Link>
                    </div>
                </div>
            )
    }

    render() {
        return this.renderPosts();
    }
}

export default ArchivePosts